<template>
  <div id="positioner" class="lsc ssc standard-cards drillsounds">
    <ActivityHeader
      activity-type="<span class='twoline-title'><span>Drill Sounds</span><br><span>/Warm-Up</span></span>"
      activity-name="Standard Sound Cards"
      :back-button-title="backButtonTitle"
      back-button-route="choosedrillsounds"
    />
    <div style="position: relative; flex: 1 0 auto;">
      <div id="card-positioner">
        <div id="cards-container" class="standard-card-container">
          <LetterCard
            v-for="card in cardsToShow"
            :isSoundCard="true"
            :key="card.cardId"
            :card="card"
            :isSelected="selectedStandardCards.includes(card.cardId) || (card.vowel === 1 && (programId !== 'funk' && programId !== 'fun1'))"
            :cardClass="card.color + ' sc'"
            :isSelectable="selectionMode"
            @cardClicked="cardClicked"
            :isNewCard="newCards.includes(card.cardId)"
            :showNewCards="showNewCards"
            :isDraggable="false"
            data-automationId="standard_sound_cards_text"
          ></LetterCard>
        </div>
      </div>
      <div id="standard-card-buttons" v-show="!isModalVisible">
        <span class="maxCardsMessage slideOutMessage" v-bind:class="{ shown : maxCardsMessage }">Try not to add more than a few new or challenging sounds, plus 4-5 consonants to the pre-selcted vowels for review</span>
        <div id="sc-button-group">
          <span class="tap-instruction explainer-label"><span>tap cards to select for review</span></span>
          <!-- Intentionally left commented in case of future necessity -->
          <!-- <button v-if="newCards && newCards.length > 0" @click="toggleShowNew"><span class='star new-star'></span><span>New Sounds</span></button> -->
          <!-- <button @click="selectCards"><span class='star selected-star'></span><span>{{ selectButtonLabel }}</span></button> -->
        </div>
        <router-link
          v-if="programId === 'fun1' || programId === 'funk'"
          :to="{ name: 'vowelextension', params: { programId: this.programId } }"
          class="activity-tile activity-tile-in-activity"
          id="vowelextension"
        >
          <img src="/images/poster-tiles/vowel-extension.svg" />
          <span
            >Vowel
            <br />
            <span>Extension</span>
          </span>
        </router-link>
        <router-link
          v-if="programId === 'fun1'"
          :to="{ name: 'rcontrolledvowels', params: { programId: this.programId } }"
          class="activity-tile activity-tile-in-activity all-fun"
          id="rcontrolledvowels"
        >
          <img src="/images/poster-tiles/r-controlled.svg" />
          <span
            >R-Controlled
            <br />
            <span>Vowels</span>
          </span>
        </router-link>
        <router-link
        v-if="programId === 'fun1'"
        :to="{ name: 'vowelteams', params: { programId: this.programId } }"
        class="activity-tile activity-tile-in-activity all-fun"
        id="vowelteams"
        >
        <img :src="'/images/poster-tiles/vowel-teams-' + programNumber + '.svg'">
          <span
            >Vowel
            <br />
            <span>Teams</span>
          </span>
        </router-link>
      </div>
    </div>
    <div id="flashcard-buttons" v-bind:class="{ hidden : maxCardsMessage }">
      <!-- intentionally left commented - not sure if this will be needed eventually -->
      <!-- <button v-if="newCards.length > 0" @click="toggleShowNew">
        <span class='star new-star'></span>New Sounds
      </button> -->
      <button v-if="selectedStandardCards.length > 0" @click="showModal">
        <span class='star selected-star' data-automationId="standard_sound_cards_buttons"></span>Show as Flash Cards
      </button>
      <div class="explainer-labels" v-if="selectionMode && selectedStandardCards.length == 0">
        <span class="tap-instruction explainer-label"><span>tap cards to select for review</span></span>
      </div>
      <button v-if="selectionMode && selectedStandardCards.length > 0" @click="resetSelection">Reset Selection</button>
    </div>
    <modal-dialog v-show="isModalVisible" @close="closeModal" class="transparent-modal-overlay">
      <template v-slot:body>
        <div id="carousel-wrapper" class="trickwords-carousel">
          <carousel-3d
            ref="carousel"
            :controls-visible="true"
            :count="selectedStandardCards.length"
            :space="100"
            :perspective="0"
            :clickable="false"
          >
            <slide v-for="(card, i) in cardsForModal" :index="i" :key="i">
              <div :class="card.color + ' in-carousel large-card'">
                <span class="lc-letter" data-automationId="standard_sound_cards_selected" v-html="card.label"></span>
                <span class="counter">{{ i + 1 }} of {{ selectedStandardCards.length }}</span>
              </div>
            </slide>
          </carousel-3d>
        </div>
      </template>
    </modal-dialog>

    <PrevNextButtons v-if="(programId !== 'funk' && programId !== 'fun1')" activity="SC"></PrevNextButtons>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { Carousel3d, Slide } from 'vue-carousel-3d';
import ActivityHeader from '@/components/ActivityHeader.vue';
import LetterCard from '@/components/LetterCard.vue';
import cardSizer from '@/mixins/cardsizer';
import ModalDialog from '@/components/ModalDialog.vue';
import PrevNextButtons from '@/components/PrevNextButtons.vue';

export default {
  name: 'StandardSoundCards',
  components: { ActivityHeader, LetterCard, ModalDialog, Carousel3d, Slide, PrevNextButtons },

  mixins: [cardSizer],
  data() {
    const isModalVisible = false;
    const selectionMode = true;
    const cardsToShow = [];
    const newCards = [];
    const showNewCards = true;
    const maxCardsMessage = false;
    const initialResize = 0;
    const backButtonTitle = '';
    const programNumber = this.programId.replace(/\D/g, '');
    return { isModalVisible, programNumber, initialResize, selectionMode, cardsToShow, newCards, showNewCards, maxCardsMessage, backButtonTitle };
  },
  props: {
    programId: { type: String },
    unitId: { type: String },
    weekId: { type: String },
    dayId: { type: String },
  },
  computed: {
    ...mapState('buildwords', ['programCardData']),
    ...mapGetters('appState', ['selectedStandardCards']),
    ...mapGetters('dataStore', ['weekNumber', 'dayNumber']),
    selectButtonLabel() {
      return this.selectionMode ? 'Done' : 'Select Cards';
    },
    vowelTeamsClass() {
      return ((this.programNumber === '3') ? '' : 'all-fun');
    },
    cardsForModal() {
      if (!this.cardsToShow || this.cardsToShow.length === 0) {
        return [];
      }
      const s = this.selectedStandardCards.map((c) => this.cardsToShow.find((f) => f.cardId === c)).filter((c) => c !== undefined);
      return s;
    },
  },
  watch: {
    isModalVisible: (val) => {
      if (val) {
        Vue.nextTick(() => window.dispatchEvent(new Event('resize')));
      }
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeMethods);
  },
  mounted() {
    this.getCardsForDisplay();
    // this.clearSelectedStandardCards({ programId: this.programId });
    window.addEventListener('resize', this.resizeMethods);
    this.backButtonTitle = this.weekId === '0' ? 'Back' : 'Drill Sounds';
    this.toggleNewCards();
  },
  updated() {
    this.initialResizeTest();
  },
  methods: {
    ...mapActions('buildwords', ['setSelected', 'clearSelected', 'unselect', 'setDisplayPosition', 'setOverridePosition', 'setNewPosition']),
    ...mapActions('appState', [
      'setSelectedStandardCards',
      'addSelectedStandardCard',
      'removeSelectedStandardCard',
      'clearSelectedStandardCards',
    ]),
    // turns off stars on new cards when this activity is viewed at the unit level.
    toggleNewCards() {
      if (this.weekId === '0') {
        this.showNewCards = false;
      }
    },
    getCardsForDisplay() {
      const programData = this.programCardData.find((c) => c.program === ((this.programId === 'funk' || this.programId === 'fun1') ? 'fun2' : this.programId));
      // const weekNumber = this.weekNumber(this.programId, this.unitId, this.weekId);
      const dayNumber = this.dayNumber(this.programId, this.unitId, this.weekId, this.dayId, false);
      if (programData) {
        this.cardsToShow = programData.cardData.cards;

        this.setDisplayPosition({ cards: this.cardsToShow });

        // const xref = programData.cardData.cardXref.find(f => f.weeks.includes(weekNumber));
        const xref = programData.cardData.cardXref.find((f) => f.days.includes(dayNumber));

        if (xref) {
          const excludeBase = !!xref.excludeBase;
          let additionalCardsToShow = programData.cardData.restOfTheCards.filter((element) => xref.cards.includes(element.cardId));
          additionalCardsToShow = additionalCardsToShow.filter((f) => !f.suffix);
          this.newCards = xref.newCards || [];
          this.setDisplayPosition({ cards: additionalCardsToShow });

          if (!excludeBase) {
            this.cardsToShow = this.cardsToShow.concat(additionalCardsToShow);
            if (xref.excludeFromBase) {
              this.cardsToShow = this.cardsToShow.filter((f) => !xref.excludeFromBase.includes(f.cardId));
            }
          } else {
            this.cardsToShow = additionalCardsToShow;
          }
          const overrides = xref.positionOverride;
          if (overrides) {
            this.setOverridePosition({ cards: this.cardsToShow, overrides });
          }
        }
        this.cardsToShow = this.cardsToShow.filter((c) => c.label !== ''); // take off blank cards for drill sounds
        this.newCards = this.newCards.filter((f) => this.cardsToShow.find((ff) => ff.cardId === f));
        if (this.programId === 'fun1') {
          // remove cards specific for level 1
          this.cardsToShow = this.cardsToShow.filter((c) => !(c.cardId >= 47 && c.cardId <= 51));
          this.cardsToShow = this.cardsToShow.filter((c) => !(c.cardId >= 33 && c.cardId <= 35)); // F, L, S
          let card = this.cardsToShow.find((c) => c.cardId === 36); // move 'all' over
          this.setNewPosition({ card, row: card.row, col: 1 });
          card = this.cardsToShow.find((c) => c.cardId === 37); // move 'am' over
          this.setNewPosition({ card, row: card.row, col: 2 });
          card = this.cardsToShow.find((c) => c.cardId === 38); // move 'an' over
          this.setNewPosition({ card, row: card.row, col: 3 });
        }

        if (this.programId === 'fun3') {
          // special stuff for level 3...
          // no 'tch' card on 1/1/2
          this.cardsToShow = this.cardsToShow.filter((c) => !(c.cardId === 311 && dayNumber === 2));
        }
      }
    },
    initialResizeTest() {
      if (this.initialResize === 0) {
        this.resizeMethods();
      }
      this.initialResize = 1;
    },
    selectCards() {
      if (!this.selectionMode) {
        this.clearSelectedStandardCards();
      }
      this.selectionMode = !this.selectionMode;
    },
    cardClicked(card) {
      if (this.selectionMode) {
        const didSelectedCard = this.selectedStandardCards.find((c) => c === card.cardId);
        if (!didSelectedCard) {
          if (this.selectedStandardCards.length < 10 || (this.programId === 'funk' || this.programId === 'fun1')) {
            this.addSelectedStandardCard({ cardId: card.cardId });
            this.maxCardsMessage = false;
          } else {
            this.maxCardsMessage = true;
            setTimeout(() => {
              this.maxCardsMessage = false;
            }, 5000);
          }
        } else {
          this.removeSelectedStandardCard({ cardId: card.cardId });
          this.maxCardsMessage = false;
        }

      }
    },
    showModal() {
      this.isModalVisible = true;
      this.$refs.carousel.goSlide(0);
      this.hideTrayButtons();
      // this.resizeCarouselFonts();
    },
    hideTrayButtons() {
      document.getElementById('flashcard-buttons').classList.add('hide-buttons');
    },
    showTrayButtons() {
      document.getElementById('flashcard-buttons').classList.remove('hide-buttons');
    },
    closeModal() {
      this.isModalVisible = false;
      this.showTrayButtons();
    },
    getImagePath(image) {
      return `/images${image}.svg`.toLocaleLowerCase();
    },
    resetSelection() {
      this.clearSelectedStandardCards();
    },
    resizeFonts() {
      const cardWidth = document.getElementsByClassName('sc')[0].clientWidth;
      const x = document.getElementsByClassName('sc-letter');
      for (let i = 0; i < x.length; i += 1) {
        x[i].setAttribute('style', `font-size: ${cardWidth * 0.4}px`);
      }
    },
    toggleShowNew() {
      this.showNewCards = !this.showNewCards;
    },
    resizeMethods() {
      this.resizeCards();
      this.resizeFonts();
    },
  },
};
</script>

<style lang="scss" scoped>
  #flashcard-buttons{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    padding-bottom: 1rem;
    width: 100%;
    opacity: 1;
    transition: all 1s ease-in-out;
    z-index: 1;
    &.hidden{
      opacity: .1;
    }
    button {
      margin: .5rem;
      padding-left: 3rem;
      padding-right: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all .5s ease-in-out;

      .star{
        position: relative;
        margin: 0 .4rem .4rem 0;
      }
    }
    &.hide-buttons{
      button{
        transform:translateY(10rem);
      }
    }
  }
  .modal {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: unset;
    max-height: 100%;
    background: rgba(255, 255, 255, 0.6);
    .modal-header {
      height: 10%;
      box-sizing: border-box;
      visibility: hidden;
    }
    .modal-footer {
      height: 10%;
      padding: 0 0 1rem 0;
      box-sizing: border-box;
      border-top: none;
      button {
        margin: auto;
      }
    }
    .modal-body {
      height: 80%;
      box-sizing: border-box;
      padding: 0;
    }
    #carousel-wrapper {
      height: 100%;
      width: 100%;
      max-width: 100%;
      .carousel-3d-container {
        width: 50% !important;
        overflow: visible;
        position: relative;
        display: flex;
        margin: auto;

        .carousel-3d-slider {
          margin: auto;
        }
        .carousel-3d-slide {
          border: none;
          overflow: visible;
          .large-card {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            border-radius: 5px;
            box-shadow: 4px 3px 7px rgba(0, 0, 0, 0.5);

            .ssc & {
              .lc-letter {
                font-family: wbookman;
                font-size: 15rem;
                text-align: center;
                margin-top: auto;
                margin-bottom: auto;
              }
            }
            .lc-img {
              width: 100%;
              max-width: 80%;
              -webkit-box-flex: 0;
              flex: 0 0 30%;
              margin: auto;
            }
            .lc-sound {
              font-family: wbookman;
              font-size: 120%;
              margin: auto;
            }
            .counter {
              color: black;
              font-style: italic;
              text-transform: unset;
              font-family: open sans;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
  #card-positioner {
    right: 25%;
    .standard-card-container{
      margin: auto;
    }
  }
  .maxCardsMessage{
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(40px);
    z-index: -100;
    // &:before{
    //   content: '';
    //   background-color: whitesmoke;
    //   background-image: url(/images/Fun1/illustrations/whistle.svg);
    //   background-size: 30px;
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   position: absolute;
    //   right: 100%;
    //   top: 0;
    //   width: 39px;
    //   bottom: 0;
    // }
  }
  .tap-instruction{
    &.explainer-label{
      max-width: 100%;
      box-sizing: border-box;
      text-align: left;
      display: inline-block;
      span{
        white-space: unset;
        margin: 0;
      }
    }
  }
</style>
<style lang="scss">
@import '~@/styles/standardcards';

#standard-card-buttons {
  position: absolute;
  left: 75%;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-left: 4%;
  #sc-button-group {
    margin: auto;
    align-self: center;
    justify-self: center;
    button {
      margin: 0.5rem;
      padding-left: 3rem;
      padding-right: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .star{
        position: relative;
        margin: 0 .4rem .4rem 0;
      }
    }
  }
  &.hide-buttons{
    button{
      transform:translateY(10rem);
    }
  }
  @include activity-tile-width-sm;

  .activity-tile-in-activity{
    width: 65%;
    align-self: center;
    justify-self: center;
    padding-bottom: 50%;
    &:before{
      content: "poster";
    }
    &:first-of-type{
      margin-top: 4rem;
    }
  }
}

.wordbank-item {
  cursor: pointer;
}
.sc {
  cursor: pointer;
}

@media (max-width: 900px) {
  #standard-card-buttons {
    margin-left: $activity-frame-lateral-padding;
  }
}

.tap-instruction{
  background-image: url('/images/pointer.svg');
  background-size: 30px;
  background-position: center left;
  background-repeat: no-repeat;
  padding: 1rem 0 1rem 4rem;
  font-size: 2rem;
  margin: 0 auto;
}
.challenge-instruction{
  display: block;
  text-align: center;
  margin: 1.5rem auto;
  line-height: 1.3;
  span{
    white-space: normal;
  }
}
@media (max-width: 1000px), (max-height: 650px){
  #standard-card-buttons{
    .tap-instruction{
      font-size: 1rem;
      span{
        font-size: 1.3rem;
      }
    }
    a.activity-tile.activity-tile-in-activity{
      width: 55%;
      align-self: center;
      justify-self: center;
      padding-bottom: 40%;
      &:before{
        content: "poster";
      }
      &:first-of-type{
        margin-top: 2rem;
      }
      span{
        font-size: 1.5rem;
        line-height: .8;
        span{
          font-size: 1.3rem;
        }
      }
    }
  }
}
</style>
